import React from 'react'
import { Layout, Row, Column, Container, Heading, Text, Link } from 'components'
import AiNagulat from 'images/bg/ai-nagulat.png'

const bgImage = `url(${AiNagulat})`
const NotFound = () => (
  <Layout>
    <Row position="relative">
      <Column {...componentProps.backgroundImageProps} />
      <Column {...componentProps.noticeSectionWrapperProps}>
        <Container {...componentProps.noticeInformationContainerProps}>
          <Heading {...componentProps.noticeTitleProps}>Whoops!</Heading>

          <Text {...componentProps.noticeTextBodyProps}>
            The page {"you're"} requesting does not seem to exist!
          </Text>

          <Text {...componentProps.noticeRedirectTextProps.normalText}>
            Send me back{' '}
            <Link {...componentProps.noticeRedirectTextProps.linkedText}>
              home
            </Link>
          </Text>
        </Container>
      </Column>
    </Row>
  </Layout>
)

const componentProps = {
  backgroundImageProps: {
    size: 'df:[100%] sm:[col-8]',
    pt: 'df:[sp7] sm:[sp8]',
    pb: 'df:[sp8] sm:[sp7] md:[sp8]',
    bgImage: bgImage,
    bgSize: 'cover',
    bgPosition: 'center',
    bgRepeat: 'no-repeat',
  },
  noticeSectionWrapperProps: {
    size: 'df:[100%] sm:[col-4]',
    display: 'flex',
    flexDir: 'column',
    flexJustify: 'center',
    textAlign: 'center',
    position: 'df:[absolute] sm:[relative]',
    height: 'df:[100%] sm:[initial]',
  },
  noticeInformationContainerProps: {
    bg: 'rgba(255,255,255,0.5)',
    mx: 'sp3',
    px: 'sp2',
    py: 'sp3',
  },
  noticeTitleProps: {
    color: 'primary',
    fontSize: 'lg:[fsize-8]',
    mb: 'sp2',
  },
  noticeTextBodyProps: {
    fontSize: 'lg:[fsize-4]',
    mb: 'sp3',
  },
  noticeRedirectTextProps: {
    normalText: {
      fontSize: 'lg:[fsize-3]',
    },
    linkedText: {
      to: '/',
      color: 'primary',
    },
  },
}

export default NotFound
